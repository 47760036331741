import { useState } from 'react';
import { globalStoreSelectorLevels, useGlobalStore } from '../features/store';
import { getKeyByValue } from '../helpers';

export default function useLevels() {
  const levels = useGlobalStore(globalStoreSelectorLevels);
  const [currentLevel, setCurrentLevel] = useState(levels?.[0]);
  const [nextLevel, setNextLevel] = useState(levels?.[1]);
  const [levelProgress, setLevelProgress] = useState(0);
  const levelsCount = levels?.length || 0;
  const updateLevel = (coins) => {
    if (levels) {
      const userLevel = levels?.find((element, index) => (
        coins >= element?.target && coins < levels?.[index + 1]?.target
      ));
      setCurrentLevel(userLevel);
      const currentLevelKey = getKeyByValue(levels, userLevel);
      const nextLevelObj = levels?.[currentLevelKey + 1];
      if (nextLevelObj) {
        setCurrentLevel(userLevel);
        setNextLevel(nextLevelObj);

        const nextLevelGoal = nextLevelObj?.target || 0;
        const progress = 100 - (((nextLevelGoal - coins) / (nextLevelGoal - (userLevel?.target || 0))) * 100);

        setLevelProgress(progress);
      } else {
        setNextLevel(false);
        setLevelProgress(100);
        setCurrentLevel(userLevel);
      }
    }
  };
  return {
    updateLevel,
    currentLevel,
    nextLevel,
    levelsCount,
    levelProgress,
  };
}
