import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

export default function Home() {
  const WebApp = useWebApp();
  useEffect(() => {
    if (WebApp) {
      WebApp.setHeaderColor('#130101');
      WebApp.setBackgroundColor('#130101');
      WebApp.enableClosingConfirmation(true);
    }
  }, []);
  return (
    <div className="game-selector">
      <NavLink to="/clicker" className="game-selector__item">Play clicker</NavLink>
      <NavLink to="/race" className="game-selector__item">Play PERDOON RACE</NavLink>
    </div>
  );
}
