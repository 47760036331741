export const APP_ROUTE_HOME = '/';
export const APP_ROUTE_GAMES_CLICKER = '/clicker';
export const APP_ROUTE_GAMES_RACE = '/race';
export const APP_ROUTE_PROFILE = '/profile';
export const APP_ROUTE_WALLET = '/wallet';
export const APP_ROUTE_STATS = '/stats';
export const APP_ROUTE_SHOP = '/shop';

export const { REACT_APP_API_URL } = process.env;

export const INIT_COINS_PER_CLICK = 1;
export const INIT_ENERGY = 1000;
export const INIT_BNB_PER_HOUR = 0;
