import { BrowserRouter } from 'react-router-dom';
import MainRoutes from '../MainRoutes';

export default function MainApp() {
  return (
    <BrowserRouter>
      <MainRoutes />
    </BrowserRouter>
  );
}
