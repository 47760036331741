import { createPortal } from 'react-dom';
import { Outlet } from 'react-router-dom';

export default function PrivateRoutes({ access }) {
  return (
    access ? (
      <Outlet />
    ) : (
      createPortal(
        <div className="access-denied">
          Access denied
        </div>,
        document.body,
      )
    )
  );
}
