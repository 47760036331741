import { CircularProgressbar } from 'react-circular-progressbar';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import SVGLogo from '../../../assets/svg/SVGLogo';
import BottomMenu from '../BottomMenu';
import {
  globalStoreSelectorProfile, globalStoreSelectorUserBoosts, useGlobalStore,
} from '../../features/store';
import useLevels from '../../hooks/useLevels';
import Avatar from '../../../assets/img/avatar.webp';
import { INIT_BNB_PER_HOUR, INIT_COINS_PER_CLICK } from '../../constants';
import { internationalFormatNum } from '../../helpers';

export default function AppHolder({
  children,
}) {
  const user = useGlobalStore(globalStoreSelectorProfile);
  const userBoosts = useGlobalStore(globalStoreSelectorUserBoosts);
  const {
    updateLevel,
    levelProgress,
    currentLevel,
  } = useLevels();

  useEffect(() => {
    updateLevel(user?.total_clicked);
  }, [user?.total_clicked]);

  return (
    <motion.div
      className="app-holder"
      initial={{
        opacity: 0,
        transform: 'scale(0.9)',
      }}
      animate={{
        opacity: 1,
        transform: 'scale(1)',
      }}
      transition={{
        delay: 1.1,
      }}
    >
      <div className="app-holder__inset">
        <motion.div
          className="game-header"
          initial={{
            visibility: 'hidden',
            transform: 'translateY(-100%)',
          }}
          animate={{
            visibility: 'visible',
            transform: 'translateY(0%)',
          }}
          transition={{
            delay: 1.2,
          }}
        >
          {/* <div className="game-header__slogan">
            New era of
            <br />
            crypto games
          </div> */}
          <div className="game-header__logo">
            <SVGLogo />
          </div>
          <div className="game-header__profile">
            <div className="game-header__profile--id">
              #
              {user?.user_id}
            </div>
            <div className="game-header__profile--avatar">
              <CircularProgressbar value={levelProgress} />
              <img src={Avatar} alt="" />
              <span>
                {currentLevel?.levelNumber}
              </span>
            </div>
          </div>
        </motion.div>
        <motion.div
          className="game-stats"
          initial={{
            visibility: 'hidden',
            transform: 'translateY(-100%)',
          }}
          animate={{
            visibility: 'visible',
            transform: 'translateY(0%)',
          }}
          transition={{
            delay: 1.2,
          }}
        >
          <div className="game-stats__block">
            <div className="game-stats__block--title">
              Income
            </div>
            <div className="game-stats__block--value --shadows-sm">
              {`${((user?.total_clicked || 0) / 80841).toFixed(2)} BNB`}
            </div>
          </div>
          <div className="game-stats__block">
            <div className="game-stats__block--title">
              Per click
              {((userBoosts?.coinsPerClick || 0)) !== INIT_COINS_PER_CLICK ? (
                <span className="stats-badge --green">
                  +
                  {internationalFormatNum((((userBoosts?.coinsPerClick || 0)) / INIT_COINS_PER_CLICK) * 100)}
                  %
                </span>
              ) : null}
            </div>
            <div className="game-stats__block--value --shadows-sm">
              +
              {((userBoosts?.coinsPerClick || 0)) || INIT_COINS_PER_CLICK}
              {' '}
              Coins
            </div>
          </div>
          <div className="game-stats__block">
            <div className="game-stats__block--title">
              Per hour
              {((userBoosts?.incomePerHour || 0)) !== INIT_BNB_PER_HOUR ? (
                <span className="stats-badge --green">
                  +
                  {internationalFormatNum((((userBoosts?.incomePerHour || 0)) / 1) * 100)}
                  %
                </span>
              ) : null}
            </div>
            <div className="game-stats__block--value --shadows-sm">
              +
              {userBoosts?.incomePerHour}
              {' '}
              Coins
            </div>
          </div>
        </motion.div>
        {children}
      </div>
      <motion.div
        className="game-menu-outer"
        initial={{
          visibility: 'hidden',
          transform: 'translateY(100%)',
        }}
        animate={{
          visibility: 'visible',
          transform: 'translateY(0%)',
        }}
        transition={{
          delay: 1.2,
        }}
      >
        <BottomMenu />
      </motion.div>
    </motion.div>
  );
}
