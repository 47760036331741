import { useEffect, useState } from 'react';
import useGetBoosts from '../../common/api/hooks/useGetBoosts';
import SVGCoin from '../../assets/svg/SVGCoin';
import { globalStoreSelectorProfile, globalStoreSelectorUserBoosts, useGlobalStore } from '../../common/features/store';
import useLevels from '../../common/hooks/useLevels';
import StoreCard from '../../common/components/StoreCard';

export default function Shop() {
  const { mutateAsync: getBoosts, isPending } = useGetBoosts();
  const user = useGlobalStore(globalStoreSelectorProfile);
  const boosts = useGlobalStore(globalStoreSelectorUserBoosts);
  const [boostsList, setBoostsList] = useState({});
  useEffect(() => {
    getBoosts().then((boosts) => {
      setBoostsList(boosts || []);
    });
    updateLevel(user?.total_clicked);
  }, []);
  const {
    currentLevel,
    updateLevel,
  } = useLevels();
  return (
    <>
      <div className="game-balance --shadows-md">
        <SVGCoin />
        {user?.balance?.toLocaleString()}
      </div>
      {!isPending ? (
        <div className="store-cards">
          {
            Object.values(boostsList).map((boost) => (
              <StoreCard {...boost} {...boosts} currentLevel={currentLevel?.levelNumber} key={`store-card-${boost?.id}`} />
            ))
          }
        </div>
      ) : (
        <div className="store-preloader">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
    </>
  );
}
