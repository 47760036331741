export default function SVGCoin() {
  return (
    <svg width="255" height="255" viewBox="0 0 255 255" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g style={{ mixBlendMode: 'hard-light' }} filter="url(#filter0_f_2005_156)">
        <circle cx="127.513" cy="127.8" r="106.298" fill="#FF922D" fillOpacity="0.28" />
      </g>
      <g style={{ mixBlendMode: 'hard-light' }} filter="url(#filter1_f_2005_156)">
        <path d="M104.866 120.107L106.01 121.25L107.153 120.107L127.513 99.7472L147.884 120.118L149.027 121.262L150.171 120.118L162.683 107.606L163.826 106.463L162.683 105.32L128.656 71.293L127.513 70.1498L126.37 71.293L92.3542 105.309L91.2109 106.452L92.3542 107.595L104.866 120.107ZM71.0059 126.657L69.8627 127.8L71.0059 128.943L83.5181 141.455L84.6614 142.599L85.8046 141.455L98.3168 128.943L99.4601 127.8L98.3168 126.657L85.8046 114.145L84.6614 113.001L83.5181 114.145L71.0059 126.657ZM107.153 135.493L106.011 134.351L104.868 135.491L92.3391 147.987L91.1928 149.13L92.3376 150.275L92.3542 150.292L126.37 184.307L127.513 185.45L128.656 184.307L162.683 150.28L163.826 149.137L162.683 147.994L150.171 135.476L149.027 134.333L147.884 135.476L127.513 155.853L107.153 135.493ZM156.709 126.657L155.566 127.8L156.709 128.943L169.221 141.455L170.364 142.599L171.508 141.455L184.02 128.943L185.163 127.8L184.02 126.657L171.508 114.145L170.364 113.001L169.221 114.145L156.709 126.657ZM113.766 126.56L112.113 127.8L113.767 129.041L126.364 141.643L127.507 142.787L128.65 141.644L141.35 128.95L141.351 128.949L141.356 128.944L142.501 127.801L141.356 126.657L141.346 126.646L141.345 126.645L128.656 113.956L127.513 112.813L126.37 113.956L116.986 123.335L116.986 123.335L115.906 124.415L115.904 124.416L113.766 126.56ZM127.513 214.765C79.4809 214.765 40.5477 175.832 40.5477 127.8C40.5477 79.768 79.4809 40.8348 127.513 40.8348C175.545 40.8348 214.478 79.768 214.478 127.8C214.478 175.832 175.545 214.765 127.513 214.765Z" stroke="#FFAB2D" strokeWidth="3.23356" />
      </g>
      <g style={{ mixBlendMode: 'hard-light' }} filter="url(#filter2_f_2005_156)">
        <path d="M104.515 120.459L106.01 121.954L107.505 120.459L127.513 100.451L147.532 120.47L149.027 121.965L150.522 120.47L163.034 107.958L164.529 106.463L163.034 104.968L129.008 70.9413L127.513 69.4463L126.018 70.9413L92.0024 104.957L90.5074 106.452L92.0024 107.947L104.515 120.459ZM70.6542 126.305L69.1592 127.8L70.6542 129.295L83.1664 141.807L84.6614 143.302L86.1564 141.807L98.6686 129.295L100.164 127.8L98.6686 126.305L86.1564 113.793L84.6614 112.298L83.1664 113.793L70.6542 126.305ZM107.505 135.141L106.012 133.648L104.517 135.139L91.9878 147.635L90.4888 149.13L91.9858 150.627L92.0024 150.643L126.018 184.659L127.513 186.154L129.008 184.659L163.034 150.632L164.529 149.138L163.035 147.643L150.523 135.125L149.027 133.629L147.532 135.125L127.513 155.149L107.505 135.141ZM156.357 126.305L154.862 127.8L156.357 129.295L168.869 141.807L170.364 143.302L171.859 141.807L184.372 129.295L185.867 127.8L184.372 126.305L171.859 113.793L170.364 112.298L168.869 113.793L156.357 126.305ZM115.552 124.065L113.439 126.183L111.284 127.8L113.44 129.418L126.012 141.995L127.507 143.49L129.002 141.996L141.701 129.302L141.702 129.301L141.707 129.296L143.205 127.801L141.708 126.305L141.697 126.294L141.697 126.294L129.008 113.605L127.513 112.11L126.018 113.604L116.634 122.983L116.634 122.983L115.554 124.063L115.552 124.065ZM127.513 214.268C79.7556 214.268 41.0452 175.557 41.0452 127.8C41.0452 80.0427 79.7556 41.3323 127.513 41.3323C175.27 41.3323 213.981 80.0427 213.981 127.8C213.981 175.557 175.27 214.268 127.513 214.268Z" stroke="#FFAB2D" strokeWidth="4.22851" />
      </g>
      <g style={{ mixBlendMode: 'hard-light' }} filter="url(#filter3_ddi_2005_156)">
        <path d="M104.866 120.107L106.01 121.25L107.153 120.107L127.513 99.7472L147.884 120.118L149.027 121.262L150.171 120.118L162.683 107.606L163.826 106.463L162.683 105.32L128.656 71.293L127.513 70.1498L126.37 71.293L92.3542 105.309L91.2109 106.452L92.3542 107.595L104.866 120.107ZM71.0059 126.657L69.8627 127.8L71.0059 128.943L83.5181 141.455L84.6614 142.599L85.8046 141.455L98.3168 128.943L99.4601 127.8L98.3168 126.657L85.8046 114.145L84.6614 113.001L83.5181 114.145L71.0059 126.657ZM107.153 135.493L106.011 134.351L104.868 135.491L92.3391 147.987L91.1928 149.13L92.3376 150.275L92.3542 150.292L126.37 184.307L127.513 185.45L128.656 184.307L162.683 150.28L163.826 149.137L162.683 147.994L150.171 135.476L149.027 134.333L147.884 135.476L127.513 155.853L107.153 135.493ZM156.709 126.657L155.566 127.8L156.709 128.943L169.221 141.455L170.364 142.599L171.508 141.455L184.02 128.943L185.163 127.8L184.02 126.657L171.508 114.145L170.364 113.001L169.221 114.145L156.709 126.657ZM113.766 126.56L112.113 127.8L113.767 129.041L126.364 141.643L127.507 142.787L128.65 141.644L141.35 128.95L141.351 128.949L141.356 128.944L142.501 127.801L141.356 126.657L141.346 126.646L141.345 126.645L128.656 113.956L127.513 112.813L126.37 113.956L116.986 123.335L116.986 123.335L115.906 124.415L115.904 124.416L113.766 126.56ZM127.513 214.765C79.4809 214.765 40.5477 175.832 40.5477 127.8C40.5477 79.768 79.4809 40.8348 127.513 40.8348C175.545 40.8348 214.478 79.768 214.478 127.8C214.478 175.832 175.545 214.765 127.513 214.765Z" stroke="#FFC940" strokeOpacity="0.2" strokeWidth="3.23356" />
      </g>
      <g filter="url(#filter4_f_2005_156)">
        <path d="M105.306 119.667L106.01 120.371L106.713 119.667L127.513 98.8677L148.324 119.679L149.027 120.382L149.731 119.679L162.243 107.166L162.947 106.463L162.243 105.759L128.216 71.7327L127.513 71.0292L126.809 71.7327L92.7939 105.748L92.0904 106.452L92.7939 107.155L105.306 119.667ZM71.4456 127.096L70.7421 127.8L71.4456 128.504L83.9578 141.016L84.6614 141.719L85.3649 141.016L97.8771 128.504L98.5806 127.8L97.8771 127.096L85.3649 114.584L84.6614 113.881L83.9578 114.584L71.4456 127.096ZM106.713 135.933L106.011 135.23L105.307 135.932L92.7782 148.427L92.0728 149.131L92.7773 149.835L92.7939 149.852L126.809 183.867L127.513 184.571L128.216 183.867L162.243 149.841L162.946 149.137L162.243 148.434L149.731 135.916L149.027 135.212L148.324 135.916L127.513 156.732L106.713 135.933ZM157.149 127.096L156.445 127.8L157.149 128.504L169.661 141.016L170.364 141.719L171.068 141.016L183.58 128.504L184.284 127.8L183.58 127.096L171.068 114.584L170.364 113.881L169.661 114.584L157.149 127.096ZM116.345 124.855L114.175 127.03L113.149 127.8L114.175 128.57L126.804 141.204L127.507 141.908L128.211 141.204L140.911 128.51L140.911 128.509L140.916 128.504L141.621 127.801L140.917 127.096L140.906 127.085L140.906 127.085L128.216 114.396L127.513 113.693L126.81 114.396L117.425 123.774L117.425 123.775L116.346 124.854L116.345 124.855ZM127.513 215.387C79.1375 215.387 39.9259 176.175 39.9259 127.8C39.9259 79.4246 79.1375 40.213 127.513 40.213C175.888 40.213 215.1 79.4246 215.1 127.8C215.1 176.175 175.888 215.387 127.513 215.387Z" stroke="#FFD56A" strokeWidth="1.98989" />
      </g>
      <g filter="url(#filter5_f_2005_156)">
        <path d="M105.658 119.316L106.01 119.667L106.361 119.316L127.513 98.1642L148.675 119.327L149.027 119.679L149.379 119.327L161.891 106.815L162.243 106.463L161.891 106.111L127.865 72.0845L127.513 71.7327L127.161 72.0845L93.1457 106.1L92.7939 106.452L93.1457 106.804L105.658 119.316ZM71.7974 127.448L71.4456 127.8L71.7974 128.152L84.3096 140.664L84.6614 141.016L85.0131 140.664L97.5253 128.152L97.8771 127.8L97.5253 127.448L85.0131 114.936L84.6614 114.584L84.3096 114.936L71.7974 127.448ZM106.361 136.284L106.01 135.933L105.658 136.284L93.1295 148.779L92.7768 149.131L93.129 149.483L93.1457 149.5L127.161 183.516L127.513 183.867L127.865 183.516L161.891 149.489L162.243 149.137L161.891 148.786L149.379 136.268L149.027 135.916L148.675 136.268L127.513 157.436L106.361 136.284ZM157.5 127.448L157.149 127.8L157.5 128.152L170.013 140.664L170.364 141.016L170.716 140.664L183.228 128.152L183.58 127.8L183.228 127.448L170.716 114.936L170.364 114.584L170.013 114.936L157.5 127.448ZM113.978 127.8L114.502 128.193L127.156 140.852L127.507 141.204L127.859 140.852L140.559 128.158L140.56 128.157L140.565 128.152L140.917 127.8L140.565 127.448L140.554 127.437L140.554 127.437L127.865 114.748L127.513 114.396L127.161 114.748L117.777 124.126L117.777 124.126L116.697 125.206L116.697 125.206L114.502 127.407L113.978 127.8ZM127.513 215.885C78.8627 215.885 39.4284 176.45 39.4284 127.8C39.4284 79.1498 78.8627 39.7155 127.513 39.7155C176.163 39.7155 215.597 79.1498 215.597 127.8C215.597 176.45 176.163 215.885 127.513 215.885Z" stroke="white" strokeWidth="0.994943" />
      </g>
      <g style={{ mixBlendMode: 'hard-light' }} filter="url(#filter6_f_2005_156)">
        <path d="M104.867 120.107L106.01 121.25L107.154 120.107L127.514 99.7472L147.885 120.118L149.028 121.262L150.171 120.118L162.683 107.606L163.827 106.463L162.683 105.32L128.657 71.293L127.514 70.1498L126.37 71.293L92.3549 105.309L91.2117 106.452L92.3549 107.595L104.867 120.107ZM71.0067 126.657L69.8634 127.8L71.0067 128.943L83.5189 141.455L84.6621 142.599L85.8053 141.455L98.3175 128.943L99.4608 127.8L98.3175 126.657L85.8053 114.145L84.6621 113.001L83.5189 114.145L71.0067 126.657ZM107.154 135.493L106.012 134.351L104.869 135.491L92.3398 147.987L91.1936 149.13L92.3383 150.275L92.3549 150.292L126.37 184.307L127.514 185.45L128.657 184.307L162.683 150.28L163.826 149.137L162.684 147.994L150.171 135.476L149.028 134.333L147.885 135.476L127.513 155.853L107.154 135.493ZM156.71 126.657L155.566 127.8L156.71 128.943L169.222 141.455L170.365 142.599L171.508 141.455L184.021 128.943L185.164 127.8L184.021 126.657L171.508 114.145L170.365 113.001L169.222 114.145L156.71 126.657ZM113.767 126.56L112.113 127.8L113.768 129.041L126.365 141.643L127.508 142.787L128.651 141.644L141.351 128.95L141.352 128.949L141.356 128.944L142.502 127.801L141.357 126.657L141.346 126.646L141.346 126.645L128.657 113.956L127.514 112.813L126.371 113.956L116.987 123.335L116.986 123.335L115.907 124.415L115.905 124.416L113.767 126.56ZM127.514 214.765C79.4816 214.765 40.5484 175.832 40.5484 127.8C40.5484 79.768 79.4816 40.8348 127.514 40.8348C175.546 40.8348 214.479 79.768 214.479 127.8C214.479 175.832 175.546 214.765 127.514 214.765Z" stroke="#FFAB2D" strokeWidth="3.23356" />
      </g>
      <g style={{ mixBlendMode: 'hard-light' }} filter="url(#filter7_f_2005_156)">
        <path d="M104.515 120.459L106.01 121.954L107.505 120.459L127.514 100.451L147.533 120.47L149.028 121.965L150.523 120.47L163.035 107.958L164.53 106.463L163.035 104.968L129.009 70.9413L127.514 69.4463L126.019 70.9413L92.0032 104.957L90.5081 106.452L92.0032 107.947L104.515 120.459ZM70.6549 126.305L69.1599 127.8L70.6549 129.295L83.1671 141.807L84.6621 143.302L86.1571 141.807L98.6693 129.295L100.164 127.8L98.6693 126.305L86.1571 113.793L84.6621 112.298L83.1671 113.793L70.6549 126.305ZM107.505 135.141L106.012 133.648L104.517 135.139L91.9885 147.635L90.4896 149.13L91.9865 150.627L92.0032 150.643L126.019 184.659L127.514 186.154L129.009 184.659L163.035 150.632L164.53 149.138L163.036 147.643L150.523 135.125L149.028 133.629L147.533 135.125L127.513 155.149L107.505 135.141ZM156.358 126.305L154.863 127.8L156.358 129.295L168.87 141.807L170.365 143.302L171.86 141.807L184.372 129.295L185.867 127.8L184.372 126.305L171.86 113.793L170.365 112.298L168.87 113.793L156.358 126.305ZM115.553 124.065L113.44 126.183L111.284 127.8L113.441 129.418L126.013 141.995L127.507 143.49L129.003 141.996L141.702 129.302L141.703 129.301L141.708 129.296L143.206 127.801L141.709 126.305L141.698 126.294L141.698 126.294L129.009 113.605L127.514 112.11L126.019 113.604L116.635 122.983L116.634 122.983L115.555 124.063L115.553 124.065ZM127.514 214.268C79.7564 214.268 41.0459 175.557 41.0459 127.8C41.0459 80.0427 79.7564 41.3323 127.514 41.3323C175.271 41.3323 213.981 80.0427 213.981 127.8C213.981 175.557 175.271 214.268 127.514 214.268Z" stroke="#FFAB2D" strokeWidth="4.22851" />
      </g>
      <g style={{ mixBlendMode: 'hard-light' }} filter="url(#filter8_ddi_2005_156)">
        <path d="M104.867 120.107L106.01 121.25L107.154 120.107L127.514 99.7472L147.885 120.118L149.028 121.262L150.171 120.118L162.683 107.606L163.827 106.463L162.683 105.32L128.657 71.293L127.514 70.1498L126.37 71.293L92.3549 105.309L91.2117 106.452L92.3549 107.595L104.867 120.107ZM71.0067 126.657L69.8634 127.8L71.0067 128.943L83.5189 141.455L84.6621 142.599L85.8053 141.455L98.3175 128.943L99.4608 127.8L98.3175 126.657L85.8053 114.145L84.6621 113.001L83.5189 114.145L71.0067 126.657ZM107.154 135.493L106.012 134.351L104.869 135.491L92.3398 147.987L91.1936 149.13L92.3383 150.275L92.3549 150.292L126.37 184.307L127.514 185.45L128.657 184.307L162.683 150.28L163.826 149.137L162.684 147.994L150.171 135.476L149.028 134.333L147.885 135.476L127.513 155.853L107.154 135.493ZM156.71 126.657L155.566 127.8L156.71 128.943L169.222 141.455L170.365 142.599L171.508 141.455L184.021 128.943L185.164 127.8L184.021 126.657L171.508 114.145L170.365 113.001L169.222 114.145L156.71 126.657ZM113.767 126.56L112.113 127.8L113.768 129.041L126.365 141.643L127.508 142.787L128.651 141.644L141.351 128.95L141.352 128.949L141.356 128.944L142.502 127.801L141.357 126.657L141.346 126.646L141.346 126.645L128.657 113.956L127.514 112.813L126.371 113.956L116.987 123.335L116.986 123.335L115.907 124.415L115.905 124.416L113.767 126.56ZM127.514 214.765C79.4816 214.765 40.5484 175.832 40.5484 127.8C40.5484 79.768 79.4816 40.8348 127.514 40.8348C175.546 40.8348 214.479 79.768 214.479 127.8C214.479 175.832 175.546 214.765 127.514 214.765Z" stroke="#FFC940" strokeOpacity="0.2" strokeWidth="3.23356" />
      </g>
      <g filter="url(#filter9_f_2005_156)">
        <path d="M105.307 119.667L106.01 120.371L106.714 119.667L127.514 98.8677L148.324 119.679L149.028 120.382L149.732 119.679L162.244 107.166L162.947 106.463L162.244 105.759L128.217 71.7327L127.514 71.0292L126.81 71.7327L92.7946 105.748L92.0911 106.452L92.7946 107.155L105.307 119.667ZM71.4464 127.096L70.7428 127.8L71.4464 128.504L83.9586 141.016L84.6621 141.719L85.3656 141.016L97.8778 128.504L98.5814 127.8L97.8778 127.096L85.3656 114.584L84.6621 113.881L83.9586 114.584L71.4464 127.096ZM106.714 135.933L106.011 135.23L105.308 135.932L92.7789 148.427L92.0735 149.131L92.778 149.835L92.7946 149.852L126.81 183.867L127.514 184.571L128.217 183.867L162.244 149.841L162.947 149.137L162.244 148.434L149.732 135.916L149.028 135.212L148.324 135.916L127.514 156.732L106.714 135.933ZM157.149 127.096L156.446 127.8L157.149 128.504L169.662 141.016L170.365 141.719L171.069 141.016L183.581 128.504L184.284 127.8L183.581 127.096L171.069 114.584L170.365 113.881L169.662 114.584L157.149 127.096ZM116.345 124.855L114.176 127.03L113.15 127.8L114.176 128.57L126.804 141.204L127.508 141.908L128.211 141.204L140.911 128.51L140.912 128.509L140.917 128.504L141.622 127.801L140.917 127.096L140.907 127.085L140.906 127.085L128.217 114.396L127.514 113.693L126.81 114.396L117.426 123.774L117.426 123.775L116.346 124.854L116.345 124.855ZM127.514 215.387C79.1382 215.387 39.9266 176.175 39.9266 127.8C39.9266 79.4246 79.1382 40.213 127.514 40.213C175.889 40.213 215.101 79.4246 215.101 127.8C215.101 176.175 175.889 215.387 127.514 215.387Z" stroke="#FFD56A" strokeWidth="1.98989" />
      </g>
      <g filter="url(#filter10_f_2005_156)">
        <path d="M105.659 119.316L106.01 119.667L106.362 119.316L127.514 98.1642L148.676 119.327L149.028 119.679L149.38 119.327L161.892 106.815L162.244 106.463L161.892 106.111L127.865 72.0845L127.514 71.7327L127.162 72.0845L93.1464 106.1L92.7946 106.452L93.1464 106.804L105.659 119.316ZM71.7981 127.448L71.4464 127.8L71.7981 128.152L84.3103 140.664L84.6621 141.016L85.0139 140.664L97.5261 128.152L97.8778 127.8L97.5261 127.448L85.0139 114.936L84.6621 114.584L84.3103 114.936L71.7981 127.448ZM106.362 136.284L106.011 135.933L105.659 136.284L93.1302 148.779L92.7775 149.131L93.1298 149.483L93.1464 149.5L127.162 183.516L127.514 183.867L127.865 183.516L161.892 149.489L162.244 149.137L161.892 148.786L149.38 136.268L149.028 135.916L148.676 136.268L127.514 157.436L106.362 136.284ZM157.501 127.448L157.149 127.8L157.501 128.152L170.013 140.664L170.365 141.016L170.717 140.664L183.229 128.152L183.581 127.8L183.229 127.448L170.717 114.936L170.365 114.584L170.013 114.936L157.501 127.448ZM113.979 127.8L114.503 128.193L127.156 140.852L127.508 141.204L127.86 140.852L140.56 128.158L140.56 128.157L140.566 128.152L140.918 127.8L140.566 127.448L140.555 127.437L140.555 127.437L127.865 114.748L127.514 114.396L127.162 114.748L117.778 124.126L117.778 124.126L116.698 125.206L116.698 125.206L114.503 127.407L113.979 127.8ZM127.514 215.885C78.8634 215.885 39.4291 176.45 39.4291 127.8C39.4291 79.1498 78.8634 39.7155 127.514 39.7155C176.164 39.7155 215.598 79.1498 215.598 127.8C215.598 176.45 176.164 215.885 127.514 215.885Z" stroke="white" strokeWidth="0.994943" />
      </g>
      <defs>
        <filter id="filter0_f_2005_156" x="0.321047" y="0.607668" width="254.384" height="254.384" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="10.4469" result="effect1_foregroundBlur_2005_156" />
        </filter>
        <filter id="filter1_f_2005_156" x="18.0371" y="18.3242" width="218.952" height="218.952" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="10.4469" result="effect1_foregroundBlur_2005_156" />
        </filter>
        <filter id="filter2_f_2005_156" x="31.9663" y="32.2534" width="191.093" height="191.093" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3.4823" result="effect1_foregroundBlur_2005_156" />
        </filter>
        <filter id="filter3_ddi_2005_156" x="29.8769" y="37.1286" width="195.272" height="195.272" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3.23356" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.224 0 0 0 0 0.15 0 0 0 0 0 0 0 0 0.7 0" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="6.9646" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.56 0 0 0 0 0.44 0 0 0 0 0 0 0 0 1 0" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-0.323356" dy="0.646713" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="shape" result="effect3_innerShadow_2005_156" />
        </filter>
        <filter id="filter4_f_2005_156" x="38.4334" y="38.7205" width="178.159" height="178.159" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        </filter>
        <filter id="filter5_f_2005_156" x="38.5993" y="38.8864" width="177.827" height="177.827" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        </filter>
        <filter id="filter6_f_2005_156" x="18.0378" y="18.3242" width="218.952" height="218.952" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        </filter>
        <filter id="filter7_f_2005_156" x="31.967" y="32.2534" width="191.093" height="191.093" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3.4823" result="effect1_foregroundBlur_2005_156" />
        </filter>
        <filter id="filter8_ddi_2005_156" x="29.8777" y="37.1286" width="195.272" height="195.272" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3.23356" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.224 0 0 0 0 0.15 0 0 0 0 0 0 0 0 0.7 0" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="6.9646" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.56 0 0 0 0 0.44 0 0 0 0 0 0 0 0 1 0" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2005_156" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-0.323356" dy="0.646713" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="shape" result="effect3_innerShadow_2005_156" />
        </filter>
        <filter id="filter9_f_2005_156" x="38.4342" y="38.7205" width="178.159" height="178.159" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.248736" result="effect1_foregroundBlur_2005_156" />
        </filter>
        <filter id="filter10_f_2005_156" x="38.6" y="38.8864" width="177.827" height="177.827" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.165824" result="effect1_foregroundBlur_2005_156" />
        </filter>
      </defs>
    </svg>
  );
}
