import { useMutation } from '@tanstack/react-query';
import { getBoosts } from '..';

export default function useGetBoosts() {
  return useMutation(
    {
      mutationFn: getBoosts,
    },
  );
}
