import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { mergeDeepLeft } from 'ramda';
import { slice } from './slice';

const store = (set) => ({
  main: slice(set),
  energy: 1000,
});

export const useGlobalStore = create(devtools(persist(immer(store), {
  name: 'user-store',
  storage: createJSONStorage(() => localStorage),
  blacklist: ['system'],
  merge: (persistedState, currentState) => mergeDeepLeft(persistedState, currentState),
})));

const state = useGlobalStore.getState();
const { main } = state;

// main
export const {
  setUser, updateBalance, setUserBoosts, setBoostsList, updateTotalClicked, setLevels,
} = main;

export const globalStoreSelectorProfile = (state) => state.main.user;
export const globalStoreSelectorBoosts = (state) => state.main.boosts;
export const globalStoreSelectorUserBoosts = (state) => state.main.user.boosts;
export const globalStoreSelectorEnergy = (state) => state.main.energy;
export const globalStoreSelectorLevels = (state) => state.main.levels;
