import { Canvas, useFrame } from '@react-three/fiber';
import { Environment, useGLTF } from '@react-three/drei';
import { useEffect, useRef } from 'react';
import { BackButton, useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useNavigate } from 'react-router-dom';
import FrameLimiter from '../../../common/three/FrameLimiter';
import useMouseControls from '../../../common/hooks/useMouseControls';

export default function Race() {
  const WebApp = useWebApp();
  useEffect(() => {
    if (WebApp) {
      WebApp.setHeaderColor('#000000');
      WebApp.setBackgroundColor('#000000');
      WebApp.enableClosingConfirmation(true);
    }
  }, []);
  const navigate = useNavigate();
  return (
    <div className="race-canvas">
      <BackButton onClick={() => navigate(-1)} />
      <Canvas flat dpr={[1, 2]} camera={{ position: [0, 8, 18], fov: 65 }} shadows>
        <color attach="background" args={[0, 0, 0]} />
        <fog attach="fog" args={['black', 0, 150]} />

        <Car rotation={[Math.PI * 1.5, 0, Math.PI]} scale={[1.5, 1.5, 1.5]} position={[0, 0.6, 4]} />
        <Road />

        <Environment background={false} preset="night" blur={1} />
        <FrameLimiter fps={30} />
      </Canvas>
    </div>
  );
}

function Car(props) {
  const { nodes, materials } = useGLTF('/glb/car.glb');
  const car = useRef();
  const {
    pointerDownFn,
    pointerMoveFn,
  } = useMouseControls(
    car,
    ['x'],
    { x: [-5.5, 5.5] },
  );

  return (
    <group {...props} onPointerDown={pointerDownFn} onPointerMove={pointerMoveFn} ref={car}>
      <mesh receiveShadow castShadow geometry={nodes.Plane006_0.geometry} material={materials['Material.001']} />
      <mesh receiveShadow castShadow geometry={nodes.Plane006_1.geometry} material={materials['Material.001']} />
      <pointLight intensity={0.2} color={[1, 0, 0]} distance={15} position={[0.5, 1.7, 0.15]} />
      <pointLight intensity={0.2} color={[1, 0, 0]} distance={15} position={[-0.5, 1.7, 0.15]} />
      <pointLight intensity={0.5} color={[1, 1, 1]} distance={15} position={[0.5, -2, 0]} />
      <pointLight intensity={0.5} color={[1, 1, 1]} distance={15} position={[-0.5, -2, 0]} />
    </group>
  );
}

function Road() {
  const { nodes, materials } = useGLTF('/glb/road.glb');
  const mesh = useRef();

  let z = 0;

  useFrame(() => {
    z += 0.3;
    if (z >= 9.99) {
      z = 0;
    }
    mesh.current.position.z = z;
  });

  const mapMashes = Array.from(Array(15).keys());
  let zRepeat = 20;

  return (
    <group receiveShadow={false} ref={mesh} rotation={[Math.PI * 0.5, Math.PI, Math.PI / 2]}>
      {mapMashes.map(() => {
        zRepeat -= 10;
        return (
          <mesh position={[zRepeat, 0, 0]} geometry={nodes.Road_Road_0.geometry} material={materials.Road} />
        );
      })}
    </group>
  );
}


useGLTF.preload('/glb/spaceship.glb');
useGLTF.preload('/glb/road.glb');
useGLTF.preload('/glb/car.glb');
