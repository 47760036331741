import axios from 'axios';
import {
  REACT_APP_API_URL,
} from '../constants';

export const api = axios.create({
  baseURL: REACT_APP_API_URL,
});

export async function getUserData({
  initData = '',
  initDataUnsafe = {},
}) {
  try {
    const { data } = await api.post(
      '/getUserData',
      {
        initData,
        initDataUnsafe,
      },
    );
    return data;
  } catch (e) {
    return false;
  }
}

export async function earnCoins({
  initDataUnsafe = {},
  initData = '',
  coinsEarned = 0,
  totalClicks = 0,
}) {
  try {
    const { data } = await api.post(
      '/earnCoins',
      {
        initDataUnsafe,
        initData,
        coinsEarned,
        totalClicks,
      },
    );
    return data;
  } catch (e) {
    return false;
  }
}

export async function getBoosts() {
  try {
    const { data } = await api.get(
      '/getBoosts',
    );
    return data;
  } catch (e) {
    return false;
  }
}

export async function getUserBoosts({
  initDataUnsafe = {},
  initData = '',
}) {
  try {
    const { data } = await api.post(
      '/getUserBoosts',
      {
        initDataUnsafe,
        initData,
      },
    );
    return data;
  } catch (e) {
    return false;
  }
}

export async function buyBoost({
  initDataUnsafe = {},
  initData = '',
  boostId = false,
}) {
  try {
    const { data } = await api.post(
      '/buyBoost',
      {
        initDataUnsafe,
        initData,
        boostId,
      },
    );
    return data;
  } catch (e) {
    return false;
  }
}

export async function getLevels() {
  try {
    const { data } = await api.get(
      '/getLevels',
    );
    return data;
  } catch (e) {
    return false;
  }
}
