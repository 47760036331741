import { useMutation } from '@tanstack/react-query';
import { getUserBoosts } from '..';

export function useGetUserBoosts() {
  return useMutation(
    {
      mutationFn: getUserBoosts,
    },
  );
}
