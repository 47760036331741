import { useMutation } from '@tanstack/react-query';
import { earnCoins } from '..';

export default function useEarnCoins() {
  return useMutation(
    {
      mutationFn: earnCoins,
    },
  );
}
