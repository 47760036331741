import { AnimatePresence, motion } from 'framer-motion';
import SVGCheck from '../../../assets/svg/SVGCheck';
import SVGCoinStore from '../../../assets/svg/SVGCoinStore';
import SVGLock from '../../../assets/svg/SVGLock';
import useBuyBoost from '../../api/hooks/useBuyBoost';
import { setUserBoosts, updateBalance } from '../../features/store';
import { cx, internationalFormatNum } from '../../helpers';
import SVGLoader from '../../../assets/svg/SVGLoader';
import useCustomInitData from '../../hooks/useCustomInitData';

export default function StoreCard(props) {
  const { initData, initDataUnsafe } = useCustomInitData();
  const { mutateAsync: buyBoost, isPending: isBoostBuyProcess } = useBuyBoost();
  const {
    id,
    name,
    picture,
    income_per_hour: incomePerHour,
    price,
    available_from: availableFrom,
    uniq_color: uniqColor,
    currentLevel,
    ids: boughtBoosts,
  } = props;
  const isDisabled = currentLevel < availableFrom;
  const isBought = boughtBoosts?.includes(id) || false;
  const buyHandler = () => {
    buyBoost({
      initDataUnsafe,
      initData,
      boostId: id,
    }).then((data) => {
      if (data?.ok === true) {
        setUserBoosts(data?.boosts?.merged || {});
        updateBalance(data?.balance);
      } else {
        // TODO: Сделать предупреждение о нехватке уровня/коинов с учетом локализации
        // alert(data?.message);
      }
    });
  };

  return (
    <div
      className={cx('store-card', isDisabled ? '--not-available' : '', isBought ? '--bougth' : '')}
      onClick={buyHandler}
    >
      <AnimatePresence>
        {isBoostBuyProcess ? (
          <motion.div
            className="store-card__loader"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.05 }}
          >
            <SVGLoader />
            <span>
              Purchasing...
            </span>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <div className="store-card__top">
        <div className="store-card__picture" style={{ '--glow': uniqColor }}>
          <img src={picture} alt="" />
          {isDisabled
            ? <div className="store-card__picture--lock"><SVGLock /></div>
            : null}
          {isBought
            ? <div className="store-card__picture--check"><SVGCheck /></div>
            : null}
        </div>
        <div className="store-card__info">
          <div className="store-card__info--name">
            {name}
          </div>
          <div className="store-card__info--income">
            <small>
              Income per hour
            </small>
            <span className="--shadows-sm">
              <SVGCoinStore />
              {`+${incomePerHour}/hour`}
            </span>
          </div>
        </div>
      </div>
      <div className="store-card__bottom">
        {`lvl ${availableFrom}`}
        <span className="--shadows-sm">
          <SVGCoinStore />
          {internationalFormatNum(price)}
        </span>
      </div>
    </div>
  );
}
