import { useEffect, useState } from 'react';
import { useGetUserData } from '../api/hooks/useGetUserData';
import { useGetUserBoosts } from '../api/hooks/useGetUserBoosts';
import { setLevels, setUser, setUserBoosts } from '../features/store';
import useGetLevels from '../api/hooks/useGetLevels';
import useCustomInitData from './useCustomInitData';

export default function useCheckAccess() {
  const { mutateAsync: getUserData, isPending: isGetUserDataPending } = useGetUserData();
  const { mutateAsync: getUserBoosts, isPending: isGetUserBoostsPending } = useGetUserBoosts();
  const { mutateAsync: getLevels, isPending: isGetLevelsPending } = useGetLevels();
  const { initData, initDataUnsafe } = useCustomInitData();
  const [access, setAccess] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check access, get user data
    getUserData({
      initDataUnsafe,
      initData,
    }).then((data) => {
      if (data !== false) {
        setAccess(true);
        setUser(data?.user);
        getUserBoosts({
          initDataUnsafe,
          initData,
        }).then((userBoosts) => {
          setUserBoosts(userBoosts?.merged);
        });
      } else {
        setAccess(!false);
      }
    });
    // Levels array
    getLevels().then((levels) => {
      setLevels(levels);
    });
  }, []);

  useEffect(() => {
    if (!isGetUserDataPending && !isGetUserBoostsPending && !isGetLevelsPending) {
      setIsLoading(false);
    }
  }, [isGetUserDataPending, isGetUserBoostsPending]);

  return {
    access,
    isLoading,
  };
}
