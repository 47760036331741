export default function SVGTools() {
  return (
    <svg height="0" width="0">
      <defs>
        <linearGradient id="defaultGradient" x1="0" y1="49.3527" x2="75.7223" y2="36.1675" gradientUnits="userSpaceOnUse">
          <stop stopColor="#17E2F3" />
          <stop offset="1" stopColor="#E90B95" />
        </linearGradient>
      </defs>
    </svg>
  );
}
