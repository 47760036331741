import { useEffect, useState } from 'react';

export default function useMouseControls(controlledRef, update = [], limits = {}) {
  const [controlsActive, setControlsActive] = useState(false);
  const ref = controlledRef;

  const pointerDownFn = () => {
    setControlsActive(true);
    const { x, y, z } = controlledRef.current.position;
    ref.current.position.x = x;
    ref.current.position.y = y;
    ref.current.position.z = z;
  };

  const pointerMoveFn = (evt) => {
    if (controlsActive) {
      const { x: pointX } = evt.point;

      let x = pointX;
      // let y = pointY;
      // let z = pointZ;

      if (update.includes('x')) {
        if (x <= limits?.x?.[0]) {
          x = limits?.x?.[0];
        }
        if (x >= limits?.x?.[1]) {
          x = limits?.x?.[1];
        }
        ref.current.position.x = x;
      }
      // if (update.includes('y')) ref.current.position.y = y;
      // if (update.includes('z')) ref.current.position.z = z;
    }
  };

  useEffect(() => {
    const pointerUpEvt = () => {
      setControlsActive(false);
    };
    document.addEventListener('mouseup', pointerUpEvt);
    return () => document.removeEventListener('mouseup', pointerUpEvt);
  }, []);


  return {
    pointerDownFn,
    pointerMoveFn,
  };
}
