export function RETURN_NULL() {
  return null;
}

export function cx(...c) {
  const args = Array.prototype.join.call(c, ' ').trim();
  return args;
}

export function internationalFormatNum(n) {
  return new Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1 }).format(n);
}

export function rand(min, max) {
  const rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
}

export function getKeyByValue(object, value) {
  const key = Object.keys(object).find((key) => object[key] === value);
  return parseInt(key, 10);
}
