import { useMutation } from '@tanstack/react-query';
import { buyBoost } from '..';

export default function useBuyBoost() {
  return useMutation(
    {
      mutationFn: buyBoost,
    },
  );
}
