import {
  Route, Routes, useLocation,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import {
  WebAppProvider, useExpand, useHapticFeedback, useWebApp,
} from '@vkruglikov/react-telegram-web-app';
import PrivateRoutes from '../PrivateRoutes';
import Home from '../../../pages/Home';
import Profile from '../../../pages/Profile';
import Wallet from '../../../pages/Wallet';
import Stats from '../../../pages/Stats';
import Shop from '../../../pages/Shop';
import {
  APP_ROUTE_GAMES_CLICKER,
  APP_ROUTE_GAMES_RACE,
  APP_ROUTE_HOME, APP_ROUTE_PROFILE, APP_ROUTE_SHOP, APP_ROUTE_STATS, APP_ROUTE_WALLET,
} from '../../constants';
import SVGTools from '../../../assets/svg/SVGTools';
import Preloader from '../Preloader';
import useCheckAccess from '../../hooks/useCheckAccess';
import AppHolder from '../AppHolder';
import Clicker from '../../../pages/Games/Clicker';
import Race from '../../../pages/Games/Race';

export default function MainRoutes() {
  const location = useLocation();
  const { access, isLoading } = useCheckAccess();
  const [isExpanded, expand] = useExpand();
  const [impactOccured] = useHapticFeedback();
  const WebApp = useWebApp();
  useEffect(() => {
    if (!isExpanded) {
      expand();
    }
  }, [isExpanded]);
  useEffect(() => {
    if (WebApp) {
      WebApp.setHeaderColor('#130101');
      WebApp.setBackgroundColor('#130101');
      WebApp.enableClosingConfirmation(true);
    }
  }, []);
  useEffect(() => {
    impactOccured('light');
  }, [location.pathname]);
  return (
    <WebAppProvider>
      <Preloader show={isLoading} />
      <AppHolder>
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route element={<PrivateRoutes access={access} />}>
              <Route path={APP_ROUTE_HOME} index element={<Home />} />
              <Route path={APP_ROUTE_PROFILE} element={<Profile />} />
              <Route path={APP_ROUTE_WALLET} element={<Wallet />} />
              <Route path={APP_ROUTE_STATS} element={<Stats />} />
              <Route path={APP_ROUTE_SHOP} element={<Shop />} />
              <Route path={APP_ROUTE_GAMES_CLICKER} element={<Clicker />} />
              <Route path={APP_ROUTE_GAMES_RACE} element={<Race />} />
            </Route>
          </Routes>
        </AnimatePresence>
        <SVGTools />
      </AppHolder>
    </WebAppProvider>
  );
}
