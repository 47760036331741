export default function SVGLoader() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <circle fill="currentColor" className="spinner" cx="4" cy="12" r="3" />
      <circle fill="currentColor" className="spinner spinner__1" cx="4" cy="12" r="3" />
      <circle fill="currentColor" className="spinner spinner__2" cx="4" cy="12" r="3" />
      <circle fill="currentColor" className="spinner spinner__3" cx="4" cy="12" r="3" />
    </svg>
  );
}
