import { forwardRef } from 'react';
import Heart from '../../../assets/img/heart.webp';
import { RETURN_NULL } from '../../helpers';

export const Coin = forwardRef(({
  onTouchStart = RETURN_NULL,
}, ref) => (
  <button
    className="game-button"
    onTouchStart={(e) => {
      onTouchStart(e);
    }}
    ref={ref}
  >
    <img src={Heart} alt="" />
  </button>
));
