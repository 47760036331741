import { useMutation } from '@tanstack/react-query';
import { getUserData } from '..';

export function useGetUserData() {
  return useMutation(
    {
      mutationFn: getUserData,
    },
  );
}
