import { NavLink } from 'react-router-dom';
import SVGProfile from '../../../assets/svg/SVGProfile';
// import SVGWallet from '../../../assets/svg/SVGWallet';
import SVGPlay from '../../../assets/svg/SVGPlay';
import SVGStats from '../../../assets/svg/SVGStats';
import SVGRocket from '../../../assets/svg/SVGRocket';
import { cx } from '../../helpers';

export default function BottomMenu({
  className,
}) {
  return (
    <div className={cx('game-menu', className)}>
      <NavLink to="/" className="game-menu__play">
        <SVGPlay />
        <span>Play</span>
      </NavLink>
      <NavLink to="/profile">
        <SVGProfile />
        <span>Profile</span>
      </NavLink>
      {/* <NavLink to="/wallet">
        <SVGWallet />
        <span>Wallet</span>
      </NavLink> */}
      <NavLink to="/shop">
        <SVGRocket />
        <span>Shop</span>
      </NavLink>
      <NavLink to="/stats">
        <SVGStats />
        <span>Stats</span>
      </NavLink>
    </div>
  );
}
