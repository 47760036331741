import { AnimatePresence, motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import SVGLoader from '../../../assets/svg/SVGLoader';

export default function Preloader({ show = true }) {
  const [isShow, setIsShow] = useState(show);
  useEffect(() => {
    if (show === false) {
      setTimeout(() => {
        setIsShow(false);
      }, 1000);
    }
  }, [show]);
  return (
    <AnimatePresence>
      {isShow ? (
        <motion.div
          exit={{ opacity: 0, visibility: 'hidden' }}
          className="preloader"
        >
          <SVGLoader />
          Loading...
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
