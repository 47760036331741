export default function SVGEnergy() {
  return (
    <svg width="66" height="95" viewBox="0 0 66 95" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M52.2738 0.40577C52.854 0.742154 53.3031 1.26537 53.5475 1.88994C53.792 2.51452 53.8174 3.20353 53.6196 3.84441L42.942 38.5391H62.6431C63.222 38.5389 63.7884 38.7082 64.2722 39.0262C64.7561 39.3441 65.1362 39.7968 65.3657 40.3283C65.5952 40.8599 65.6641 41.447 65.5637 42.0172C65.4634 42.5874 65.1983 43.1157 64.8011 43.537L17.3716 93.9309C16.9127 94.4189 16.302 94.7371 15.6391 94.8335C14.9761 94.93 14.3001 94.7991 13.7211 94.4622C13.1421 94.1253 12.6943 93.6021 12.4507 92.9781C12.2071 92.3541 12.182 91.6659 12.3796 91.0258L23.0572 56.3252H3.35615C2.77719 56.3254 2.21083 56.1561 1.72698 55.8381C1.24314 55.5202 0.863002 55.0675 0.633491 54.536C0.40398 54.0044 0.335145 53.4173 0.435482 52.8471C0.53582 52.2769 0.800938 51.7486 1.19811 51.3273L48.6276 0.933423C49.086 0.44608 49.6957 0.128121 50.3577 0.0312459C51.0197 -0.0656292 51.695 0.0642766 52.2738 0.399841V0.40577Z" fill="url(#paint0_linear_2005_104)" />
      <defs>
        <linearGradient id="paint0_linear_2005_104" x1="13.2505" y1="-24.512" x2="100.744" y2="33.9781" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EA3498" />
          <stop offset="1" stopColor="#A6075E" />
        </linearGradient>
      </defs>
    </svg>
  );
}
