import { useInitData } from '@vkruglikov/react-telegram-web-app';

export default function useCustomInitData() {
  let initData = 'query_id=AAGy-IoJAAAAALL4igl-FmIb&user=%7B%22id%22%3A160102578%2C%22first_name%22%3A%22%D0%A2%D1%91%D0%BC%D0%B0%22%2C%22last_name%22%3A%22%D0%A0%D1%8B%D0%B1%D0%B8%D0%BD%22%2C%22username%22%3A%22arty_rybin%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1715773888&hash=4fd7ae737bc1255a51366f9150b1c22b5b770fc6677bca81c36904a12a4a4ca1';
  let initDataUnsafe = {
    query_id: 'AAGy-IoJAAAAALL4igl-FmIb',
    user: {
      id: 160102578,
      first_name: 'Тёма',
      last_name: 'Рыбин',
      username: 'arty_rybin',
      language_code: 'ru',
      is_premium: true,
      allows_write_to_pm: true,
    },
    auth_date: '1715773888',
    hash: '4fd7ae737bc1255a51366f9150b1c22b5b770fc6677bca81c36904a12a4a4ca1',
  };

  if (process.env.NODE_ENV === 'production') {
    [initDataUnsafe, initData] = useInitData();
  }

  return {
    initDataUnsafe,
    initData,
  };
}
