import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';
import { useEffect, useState } from 'react';
import useEarnCoins from '../api/hooks/useEarnCoins';
import {
  globalStoreSelectorProfile, updateBalance, updateTotalClicked, useGlobalStore,
} from '../features/store';
import { rand } from '../helpers';
import useCustomInitData from './useCustomInitData';

export default function useCoin({
  initEnergy = 999,
  initMaxEnergy = 999,
  coinsPerClick = 1,
  coinRef = null,
  autoClicker = 0,
}) {
  const { initDataUnsafe, initData } = useCustomInitData();
  const {
    balance, total_clicked: totalClicked, auth_timestamp: authTimestamp, restore_energy: restoreEnergyTimestamp,
  } = useGlobalStore(globalStoreSelectorProfile);
  const [coinsSession, setCoinsSession] = useState(0);
  const [clicksSession, setClicksSession] = useState(0);
  const [energy, setEnergy] = useState(initEnergy);
  const [maxEnergy, setMaxEnergy] = useState(initMaxEnergy);
  const { mutateAsync: earnCoins } = useEarnCoins();
  const [impactOccured] = useHapticFeedback();

  // send data!
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (coinsSession !== 0) {
        earnCoins({
          initDataUnsafe,
          initData,
          coinsEarned: coinsSession,
          totalClicks: clicksSession,
        });
      }
      setCoinsSession(0);
      setClicksSession(0);
    }, 200);
    return () => clearTimeout(timeout);
  }, [balance]);

  // Update energy level
  useEffect(() => {
    const toRestore = restoreEnergyTimestamp - authTimestamp;
    if (toRestore >= 0 && initEnergy !== 0) {
      if (toRestore <= maxEnergy) {
        setEnergy(initEnergy - toRestore);
      }
    }
  }, [initEnergy]);

  const clickHandler = (e) => {
    if (energy > 0) {
      updateBalance(balance + coinsPerClick);
      updateTotalClicked(totalClicked + 1);
      setEnergy((current) => (current - 1));
      setCoinsSession((current) => (current + coinsPerClick));
      setClicksSession((current) => (current + 1));
      impactOccured('light');
      spawnEntity(e, coinsPerClick);
    }
  };

  const spawnEntity = (e, content) => {
    const { screenX, screenY } = e?.touches?.[0] || {};
    const element = document.createElement('div');

    element.innerHTML = `+${content}`;
    element.classList.add('popup-digit');
    element.style.top = `${screenY}px`;
    element.style.left = `${screenX}px`;

    document.body.append(element);

    setTimeout(() => {
      element.remove();
    }, 900);
  };

  // Autoclicker
  useEffect(() => {
    const interval = setInterval(() => {
      if (autoClicker && coinRef?.current) {
        const {
          left, right, top, bottom,
        } = coinRef.current.getBoundingClientRect();
        const e = {
          touches: [{ screenX: rand(left, right), screenY: rand(top, bottom) }],
        };
        spawnEntity(e, autoClicker);
        updateBalance(balance + autoClicker);
        updateTotalClicked(totalClicked + 1);
        impactOccured('light');
        earnCoins({
          initDataUnsafe,
          initData,
          coinsEarned: coinsSession,
          totalClicks: clicksSession,
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [balance]);

  const restoreEnergy = () => {
    if (energy < maxEnergy) {
      setEnergy((current) => (current + 1));
    }
  };

  return {
    clickHandler,
    restoreEnergy,
    setMaxEnergy,
    energy,
    maxEnergy,
  };
}
