import { useEffect, useRef } from 'react';
import SVGCoin from '../../../assets/svg/SVGCoin';
import SVGEnergy from '../../../assets/svg/SVGEnergy';
import useLevels from '../../../common/hooks/useLevels';
import useCoin from '../../../common/hooks/useCoin';
import { Coin } from '../../../common/components/Coin';
import { globalStoreSelectorProfile, globalStoreSelectorUserBoosts, useGlobalStore } from '../../../common/features/store';

export default function Clicker() {
  const {
    balance, total_clicked: totalClicked,
  } = useGlobalStore(globalStoreSelectorProfile);
  const boosts = useGlobalStore(globalStoreSelectorUserBoosts);
  const coinRef = useRef();
  // Clicker
  const {
    clickHandler, restoreEnergy, maxEnergy, energy,
  } = useCoin({
    initEnergy: (boosts?.energyExpansion || 0),
    initMaxEnergy: (boosts?.energyExpansion || 0),
    coinsPerClick: (boosts?.coinsPerClick || 0),
    autoClicker: boosts?.autoClicker,
    coinRef,
  });

  // Restore energy
  useEffect(() => {
    const interval = setInterval(() => {
      restoreEnergy();
    }, 1000);
    return () => clearInterval(interval);
  }, [energy]);

  const {
    updateLevel,
    currentLevel,
    nextLevel,
    levelsCount,
    levelProgress,
  } = useLevels();

  useEffect(() => {
    updateLevel(totalClicked);
  }, [totalClicked]);

  return (
    <>
      <div className="game-balance --shadows-md">
        <SVGCoin />
        {balance.toLocaleString()}
      </div>

      <Coin
        onTouchStart={(e) => {
          clickHandler(e);
        }}
        coinsPerClick={boosts?.coinsPerClick}
        ref={coinRef}
      />

      <div className="game-footer">
        <div className="game-energy">
          <span>
            <SVGEnergy />
            { `${maxEnergy} / ${energy}` }
          </span>
          <div className="game-level__info--numbers">
            Level
            {' '}
            <b>
              {`${currentLevel?.levelNumber} / ${levelsCount - 1}`}
            </b>
          </div>
        </div>
        <div className="game-level">
          <div className="game-level__info">
            <div className="game-level__info--current">
              <span>
                {currentLevel?.levelName}
                {' '}
                { nextLevel !== false ? (
                  <>
                    &gt;
                    {' '}
                    {`${nextLevel?.levelName}`}
                  </>
                ) : null}
              </span>
              <span>
                { nextLevel !== false ? `${totalClicked}/${nextLevel?.target}` : null }
              </span>
            </div>
          </div>
          <div className="game-level__progress">
            <div className="game-level__progress--bar" style={{ width: `${levelProgress}%` }}>
              <span>{currentLevel?.levelNumber}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
