/* eslint-disable no-param-reassign */

import { INIT_ENERGY } from '../../../constants';

const initialState = {
  user: {
    total_clicked: 0,
    balance: 0,
    energy: INIT_ENERGY,
  },
  levels: [],
};

export const slice = (set) => ({
  ...initialState,
  setUser: (user) => set((state) => {
    state.main.user = user;
  }),
  logoutUser: () => set((state) => {
    state.main = { ...state.main, ...initialState };
  }),
  updateBalance: (balance) => set((state) => {
    state.main.user.balance = balance;
  }),
  updateTotalClicked: (balance) => set((state) => {
    state.main.user.total_clicked = balance;
  }),
  setUserBoosts: (boosts) => set((state) => {
    state.main.user.boosts = boosts;
  }),
  setBoostsList: (boosts) => set((state) => {
    state.main.boosts = boosts;
  }),
  updateEnergy: (energy) => set((state) => {
    state.main.user.energy = energy;
  }),
  setLevels: (levels) => set((state) => {
    state.main.levels = levels;
  }),
});
